import * as React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { RouteComponentProps, Link } from "@reach/router";

const NotFound: React.FC<RouteComponentProps> = () => (
  <>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Typography
        align="center"
        color="textPrimary"
        variant="h1"
        sx={{ mt: 10, mb: 3 }}
      >
        404: The page you are looking for isn’t here
      </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button component={Link} to="/" variant="outlined">
        Back to Home
      </Button>
    </Box>
  </>
);

export { NotFound };
